<template>
  <div>
    <header-top-dashboard primary></header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n16">
      <v-row>
        <v-col class="mt-n3">
          <v-card class="card-shadow mb-6">
            <!-- <div class="card-header-padding card-border-bottom">
              <div class="font-weight-600 text-h3 text-typo mb-1">
                Create a new shop
              </div>
            </div> -->

            <v-card-text class="card-padding">
              <v-form>
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <h3>Shop Details</h3>
                    <v-divider style="margin-bottom: 15px"></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Shop Name</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Shop Name"
                      v-model="shopDetails.name"
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.shopDetails.name.$errors)
                      "
                      @blur="v$.shopDetails.name.$touch"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Link Owner</label
                    >

                    <v-select
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Shop Owner"
                      :items="shopOwners"
                      :item-text="
                        (row) => {
                          return `${row.firstname} ${row.lastname}`;
                        }
                      "
                      item-value="id"
                      v-model="shopDetails.ownerId"
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.shopDetails.ownerId.$errors)
                      "
                      @blur="v$.shopDetails.ownerId.$touch"
                    >
                    </v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <h3>Adress Details</h3>
                    <v-divider style="margin-bottom: 15px"></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    v-if="addressAutocomplete"
                    cols="12"
                    md="6"
                    class="py-0"
                  >
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Search Shop Address</label
                    >
                    <vuetify-google-autocomplete
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Start typing to search the shop address..."
                      v-model="shopDetails.addressAutocompleteModel"
                      id="createShopAddress"
                      v-on:placechanged="getAddressData"
                      :error-messages="
                        getFieldValidationErrors(
                          v$.shopDetails.addressAutocompleteModel.$errors
                        )
                      "
                      @blur="v$.shopDetails.addressAutocompleteModel.$touch"
                    >
                    </vuetify-google-autocomplete>
                  </v-col>

                  <v-col
                    v-if="addressAutocomplete"
                    cols="12"
                    md="3"
                    class="py-0"
                  >
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Address Type</label
                    >
                    <v-select
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Address Type"
                      :items="addressTypes"
                      item-text="value"
                      item-value="key"
                      v-model="shopDetails.address.type"
                      :error-messages="
                        getFieldValidationErrors(
                          v$.shopDetails.address.type.$errors
                        )
                      "
                      @blur="v$.shopDetails.address.type.$touch"
                    >
                    </v-select>
                  </v-col>

                  <v-col
                    v-if="addressAutocomplete"
                    cols="12"
                    md="3"
                    class="py-0"
                  >
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      ><span style="color: transparent">.</span></label
                    >
                    <v-btn
                      @click="addressAutocomplete = false"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="font-weight-600 text-capitalize btn-outline-primary py-3 px-6 rounded-sm me-2"
                      color="white"
                      >Enter details manually</v-btn
                    >
                  </v-col>

                  <v-col
                    v-if="!addressAutocomplete"
                    cols="12"
                    md="4"
                    class="py-0"
                  >
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Address Line 1</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Address line 1"
                      v-model="shopDetails.address.addressLine1"
                      :error-messages="
                        getFieldValidationErrors(
                          v$.shopDetails.address.addressLine1.$errors
                        )
                      "
                      @blur="v$.shopDetails.address.addressLine1.$touch"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col
                    v-if="!addressAutocomplete"
                    cols="12"
                    md="4"
                    class="py-0"
                  >
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Address Line 2</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Address line 2"
                      v-model="shopDetails.address.addressLine2"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col
                    v-if="!addressAutocomplete"
                    cols="12"
                    md="4"
                    class="py-0"
                  >
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Address Line 3</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Address line 3"
                      v-model="shopDetails.address.addressLine3"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col
                    v-if="!addressAutocomplete"
                    cols="12"
                    md="4"
                    class="py-0"
                  >
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Surburb</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Start typing to search the shop address..."
                      v-model="shopDetails.address.surburb"
                      :error-messages="
                        getFieldValidationErrors(
                          v$.shopDetails.address.surburb.$errors
                        )
                      "
                      @blur="v$.shopDetails.address.surburb.$touch"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col
                    v-if="!addressAutocomplete"
                    cols="12"
                    md="4"
                    class="py-0"
                  >
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >City</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Start typing to search the shop address..."
                      v-model="shopDetails.address.city"
                      :error-messages="
                        getFieldValidationErrors(
                          v$.shopDetails.address.city.$errors
                        )
                      "
                      @blur="v$.shopDetails.address.city.$touch"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col
                    v-if="!addressAutocomplete"
                    cols="12"
                    md="4"
                    class="py-0"
                  >
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Province</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Start typing to search the shop address..."
                      v-model="shopDetails.address.province"
                      :error-messages="
                        getFieldValidationErrors(
                          v$.shopDetails.address.province.$errors
                        )
                      "
                      @blur="v$.shopDetails.address.province.$touch"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col
                    v-if="!addressAutocomplete"
                    cols="12"
                    md="3"
                    class="py-0"
                  >
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Latitude</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Start typing to search the shop address..."
                      v-model="shopDetails.address.latitude"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col
                    v-if="!addressAutocomplete"
                    cols="12"
                    md="3"
                    class="py-0"
                  >
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Longitude</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Start typing to search the shop address..."
                      v-model="shopDetails.address.longitude"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col
                    v-if="!addressAutocomplete"
                    cols="12"
                    md="3"
                    class="py-0"
                  >
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Address Type</label
                    >
                    <v-select
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Address Type"
                      :items="addressTypes"
                      item-text="value"
                      item-value="key"
                      v-model="shopDetails.address.type"
                      :error-messages="
                        getFieldValidationErrors(
                          v$.shopDetails.address.type.$errors
                        )
                      "
                      @input="v$.shopDetails.address.type.$touch"
                    >
                    </v-select>
                  </v-col>

                  <v-col
                    v-if="!addressAutocomplete"
                    cols="12"
                    md="3"
                    class="py-0"
                  >
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      ><span style="color: transparent">.</span></label
                    >
                    <v-btn
                      @click="addressAutocomplete = true"
                      elevation="0"
                      :ripple="false"
                      height="43"
                      class="font-weight-600 text-capitalize btn-outline-primary py-3 px-6 rounded-sm me-2"
                      color="white"
                      >Search Address Details</v-btn
                    >
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <h3>Contact Details</h3>
                    <v-divider style="margin-bottom: 15px"></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="2" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Country Code</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Country code"
                      v-model="shopDetails.contact.countryCode"
                      :error-messages="
                        getFieldValidationErrors(
                          v$.shopDetails.contact.countryCode.$errors
                        )
                      "
                      @blur="v$.shopDetails.contact.countryCode.$touch"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="6" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Contact Number</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Contact Number"
                      v-model="shopDetails.contact.number"
                      :error-messages="
                        getFieldValidationErrors(
                          v$.shopDetails.contact.number.$errors
                        )
                      "
                      @blur="v$.shopDetails.contact.number.$touch"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Contact Number Type</label
                    >
                    <v-select
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Contact Number Type"
                      :items="contactTypes"
                      item-text="value"
                      item-value="key"
                      v-model="shopDetails.contact.type"
                      :error-messages="
                        getFieldValidationErrors(
                          v$.shopDetails.contact.type.$errors
                        )
                      "
                      @blur="v$.shopDetails.contact.type.$touch"
                    >
                    </v-select>
                  </v-col>
                </v-row>

                <v-btn
                  @click="submit"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  :loading="submitButton.isLoading"
                  class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm me-2"
                  color="#5e72e4"
                  >Submit</v-btn
                >
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import { mapStores } from "pinia";
import { useSessionStore } from "@/store/session";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";

export default {
  name: "vouchers-create",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    HeaderTopDashboard,
  },
  // mixins: [validationMixin],
  validations() {
    return {
      shopDetails: {
        name: { required, $autoDirty: true },
        ownerId: { required },
        addressAutocompleteModel: {
          requiredIfAddressAutocomplete: requiredIf(this.addressAutocomplete),
        },
        address: {
          type: { required },
          // longitude: "",
          // latitude: "",
          addressLine1: { required },
          // addressLine2: "",
          // addressLine3: "",
          surburb: { required },
          city: { required },
          province: { required },
          code: { required },
        },
        contact: {
          type: { required },
          countryCode: { required },
          number: { required },
        },
      },
    };
  },
  data() {
    return {
      addressAutocomplete: true,
      addressAutocompleteModel: null,
      addressTypes: [],
      contactTypes: [],
      shopDetails: {
        name: "",
        ownerId: null,
        address: {
          type: "",
          longitude: "",
          latitude: "",
          addressLine1: "",
          addressLine2: "",
          addressLine3: "",
          surburb: "",
          city: "",
          province: "",
          code: "",
        },
        contact: {
          type: "",
          countryCode: "+27",
          number: "",
        },
      },
      shopOwners: [],
      submitButton: {
        isLoading: false,
      },
    };
  },
  computed: {
    ...mapStores(useSessionStore),
  },
  methods: {
    getFieldValidationErrors(errors) {
      const messages = [];

      for (let i = 0; i < errors.length; i++) {
        messages.push(`${errors[i].$message}`);
      }

      return messages;
    },

    getAddressData(addressData, placeResultData) {
      this.setAddressDetailsFromGooglePlacesApi(
        this.shopDetails.address,
        addressData,
        placeResultData
      );
    },

    async submit() {
      this.showGlobalAlert("success", "We are all good");
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        return;
      }

      this.submitButton.isLoading = true;

      const shopObject = {
        name: this.shopDetails.name,
        image_uri: "this.shopDetails.imageUri",
        users: [],
      };

      if (this.shopDetails.ownerId) {
        shopObject.users.push({
          id: this.shopDetails.ownerId,
          role: "shop_owner",
        });
      }

      const addressObject = {
        type: this.shopDetails.address.type,
        address_line_1: this.shopDetails.address.addressLine1,
        address_line_2: this.shopDetails.address.addressLine2,
        address_line_3: this.shopDetails.address.addressLine3,
        surburb: this.shopDetails.address.surburb,
        city: this.shopDetails.address.city,
        province: this.shopDetails.address.province,
        latitude: this.shopDetails.address.latitude.toString(),
        longitude: this.shopDetails.address.longitude.toString(),
      };
      // shops/1/address-details

      const contactObject = {
        country_code: this.shopDetails.contact.countryCode,
        number: this.shopDetails.contact.number,
        type: this.shopDetails.contact.type,
      };

      this.$http
        .post("/vouchers", shopObject, this.sessionStore.getHttpConfig)
        .then(async (result) => {
          const shopId = result.data.data[0].id;
          console.log(shopId);
        })
        .catch((error) => {
          console.error("Error while saving new shop: ", error);
        })
        .finally(() => {
          this.submitButton.isLoading = false;
        });
    },
  },
  async mounted() {
    this.fetchUsersByRole("shop_owner")
      .then((result) => {
        this.shopOwners.splice(0);

        for (let i = 0; i < result.data.data.length; i += 1) {
          this.shopOwners.push(result.data.data[i]);
        }
      })
      .catch((error) => {
        console.error("Error while fetching users by role: ", error);
      });

    this.fetchStaticDataByGrouping("addressTypes")
      .then((result) => {
        this.addressTypes.splice(0);

        for (let i = 0; i < result.data.data.length; i += 1) {
          this.addressTypes.push(result.data.data[i]);
        }
      })
      .catch((error) => {
        console.error("Error while fetching address types: ", error);
      });

    this.fetchStaticDataByGrouping("contactTypes")
      .then((result) => {
        this.contactTypes.splice(0);

        for (let i = 0; i < result.data.data.length; i += 1) {
          this.contactTypes.push(result.data.data[i]);
        }
      })
      .catch((error) => {
        console.error("Error while fetching contact types: ", error);
      });
  },
};
</script>
